import React from 'react';
import close from '../../assets/cancel.svg';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

const EditCertificate = ({
  open,
  onClose,
  onSubmit
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">  
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                    <div className="flex align-middle justify-between px-4 py-4">
                        <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                            Confirmation
                        </DialogTitle>
                        <div className="flex align-middle justify-end">
                            <img className="hover:cursor-pointer" onClick={onClose} src={close} alt="close-img" />
                        </div>
                    </div>
                    
                    <div className="bg-white px-4 pb-4 pt-0 sm:pb-4">
                        <div className="sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <div className="mt-2">
                                    <p className='text-center font-semibold mb-10'>Are you really want to delete this ?</p>
                                </div>
                                <div className="flex justify-center gap-10">
                                    <button className='w-1/2 bg-gray-400 rounded-md text-white' onClick={onClose}>
                                        Close
                                    </button>
                                    <button
                                        onClick={onSubmit}
                                        className="w-1/2 justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm"
                                    >
                                        Delete
                                    </button>    
                                </div>  
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </div>
    </Dialog>
  )
}

export default EditCertificate;
