import React, { Suspense, lazy } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import config from "../../../config";
const ProductsList = lazy(() => import('./ProductsList'));

function Products() {
    const handleImport = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Validate file type
        const validTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'text/csv'
        ];
        
        if (!validTypes.includes(file.type)) {
            toast.error("Please upload only Excel or CSV files");
            e.target.value = '';
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(
                `${config.baseURL}/import-products`, 
                formData,
                { 
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message || "Products imported successfully!");
                setTimeout(() => window.location.reload(), 1500);
            } else {
                toast.error(response.data.message || "Import failed. Please check your file format.");
            }
        } catch (error) {
            console.error("Import failed:", error);
            toast.error(error.response?.data?.message || "Import failed. Please try again.");
        }
        e.target.value = ''; // Reset input
    };

    const downloadTemplate = () => {
        // Create template URL
        const templateUrl = `${config.baseURL}/product-template.xlsx`;
        
        // Create temporary link
        const link = document.createElement('a');
        link.href = templateUrl;
        link.setAttribute('download', 'product_template.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="p-4 max-w-full mx-auto mt-12 font-noto">
                <ToastContainer />
                <div className="flex justify-between mb-4">
                    <p className='text-2xl font-bold text-black'>Products</p>
                    <div className="flex gap-4">
                        <button 
                            onClick={downloadTemplate}
                            className="text-blue-600 font-noto hover:underline"
                        >
                            Download Template
                        </button>

                        <label className="flex items-center gap-2 bg-blue-600 text-white font-noto rounded-xl px-4 py-2 cursor-pointer hover:bg-blue-700 transition-colors">
                            
                            Import Excel 
                            <input
                                type="file"
                                accept=".xlsx,.xls,.csv"
                                className="hidden"
                                onChange={handleImport}
                            />
                        </label>

                        <Link to='/product/add-product' className="flex items-center gap-2 bg-red text-white font-noto rounded-xl px-4 py-2 hover:bg-red-700 transition-colors">
                            Add Product
                        </Link>
                    </div>
                </div>
                <Suspense fallback={<div>Loading please wait...</div>}>
                    <ProductsList />
                </Suspense>
            </div>
        </>
    );
}

export default Products;














