
import { Routes, Route } from "react-router-dom";
import Dashboard from './app/dashboard';
import AddCategory from './app/products/category/AddCategory'
import EditCategory from "./app/products/category/EditCategory";
import AddSubCategory from "./app/products/sub-category/AddSubCategory";
import EditSubCategory from "./app/products/sub-category/EditSubCategory";
import Products from "./app/products/product/Products";
import AddProduct from "./app/products/product/AddProduct";
import EditProduct from "./app/products/product/EditProduct";
import Contact from './app/contact-us/Contact';
import Careers from './app/careers/Careers';
import AddCertificate from "./app/certificates/AddCertificate";
import AddGallery from "./app/gallery/AddGallery";

function AppRoutes(){
    return(
      <>
        <Routes>
          <Route path="/" element={<Dashboard/>} />
          <Route path="/product/categories" element={<AddCategory/>} />
          <Route path="/product/categories/:id" element={<EditCategory/>} />
          <Route path="/product/sub-categories" element={<AddSubCategory/>} />
          <Route path="/product/sub-categories/:id" element={<EditSubCategory/>} />
          <Route path="/product/products" element={<Products/>} />
          <Route path="/product/add-product" element={<AddProduct/>} />
          <Route path="/product/edit-product/:id" element={<EditProduct/>} />
          <Route path="/inquires" element={<Contact/>} />
          <Route path="/careers" element={<Careers/>} />
          <Route path="/certificates" element={<AddCertificate/>} />
          <Route path="/gallery" element={<AddGallery/>} />
        </Routes>
      </>
    )
}

export default AppRoutes