import React, { useState, useEffect, Suspense, lazy  }  from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import axios from 'axios';
import config from "../../../config";
import close from '../../../assets/cancel.svg';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductImageUpload from '../components/ProductImageUpload'
const SubCategories = lazy(() => import("./SubCategories"));

function AddSubCategory(){

    const notify = () => toast.success("Sub category added successfully!", {
        position: "top-right"
    });
    const [data,setData]=useState(false);
    const [open, setOpen] = useState(false);
    const [formdata,setFormData]=useState({
        title:'',
        mainCategory:'',
        description:'',
        image:null,
        imageSrc:null,
        imageName:null
    });
    const [errors, setErrors] = useState({}); 

    const validate = () => {
        let errors = {};
        
        if (!formdata.mainCategory) {
            errors.mainCategory = 'Category is required';
        }
        if (!formdata.title || formdata.title.trim()==="") {
          errors.title = 'Title is required';
        }
        if (!formdata.description || formdata.description.trim()==="") {
          errors.description = 'Description is required';
        }
        
        if (!formdata.image) {
          errors.image = 'Image file is required';
        } else if (!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(formdata.image.type)) {
          errors.image = 'Only PNG and JPEG, JPG, WEBP images are allowed';
        }
      
    
        return errors;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formdata, 
          [name]: value 
        });
    };

    const fileHandle=(e)=>{
        // console.log(e.target.files[0]);
        const { name } = e.target;
        const file = e.target.files[0];
        const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
        if (validTypes.includes(file.type)) {
            // setFile(file);
            setFormData((prevData) => ({
                ...formdata, 
                [name]: file 
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData((prevData) => ({
                    ...prevData,
                    imageSrc: reader.result, 
                    imageName:file.name
                }));
            };
            reader.readAsDataURL(file); 
        }else{
            e.target.value = ''; 
        }
    }

    const formHandler=(e)=>{
        e.preventDefault();
        const validationErrors = validate(); 
        if (Object.keys(validationErrors).length === 0) {
          const formData = new FormData();
          formData.append('title', formdata.title);
          formData.append('description', formdata.description);
          formData.append('mainCategory', JSON.stringify(formdata.mainCategory));
          formData.append('image', formdata.image);

          axios.put(`${config.baseURL}/add-sub-category`,formData,{ 
            withCredentials: true 
          })
          .then((res)=>{
            //console.log(res.data.data)
            if(res.data.status==="success"){
                setOpen(false);
                setFormData({
                    title:'',
                    mainCategory:"",
                    description:'',
                    image:null,
                    imageSrc:null,
                    imageName:null
                });
                setData(!data);
                notify();
            }

          })
          .catch((err)=>console.log(err))

        } else {
          setErrors(validationErrors);
        }
    }

    useEffect(() => {
      setErrors({});
    }, [open])

    const [options,setOptions]=useState()
    useEffect(()=>{
        axios.get(`${config.baseURL}/get-sub-categories`,{ 
            withCredentials: true 
        })
        .then((res)=>{
            if(res.data.status==='success'){
                const options = res.data.data.map(item => ({
                    value: item.slug, 
                    label: item.name
                }));
                setOptions(options);
            }
        })
        .catch((err)=>console.log(err))
    },[]);

    const selectHandle = (selectedOption) => {
        setFormData({
            ...formdata,
            mainCategory: selectedOption 
        });
    };


    return(
        <>
            <div className="p-4 max-w-full mx-auto mt-12 font-noto">
                <ToastContainer />
                <div className="flex justify-between mb-4">
                    <p className='text-2xl font-bold text-black'>Sub Categories</p>
                    <div>
                        <button className="bg-red text-white font-noto rounded-xl px-4 py-2" onClick={()=>setOpen(true)}>Add Sub Category</button>
                    </div>
                    <Dialog open={open} onClose={setOpen} className="relative z-10">  
                        <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        />
                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="flex align-middle justify-between px-4 py-4">
                                    <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                        Add Sub Category
                                    </DialogTitle>
                                    <div className="flex align-middle justify-end ">
                                        <img className="hover:cursor-pointer" onClick={() => setOpen(false)} src={close} alt="close-img"/>
                                    </div>
                                </div>
                                
                                <div className="bg-white px-4 pb-4 pt-0 sm:pb-4">
                                    <div className="sm:items-start">
                                        {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                                        </div> */}
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <div className="mt-2">
                                                <form onSubmit={formHandler} className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">
                                                    <div className="col-span-full">
                                                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Main Category 
                                                        </label>
                                                        <div className="mt-2">
                                                            <Select options={options} name="mainCategory" id="mainCategory" value={formdata.mainCategory}  onChange={selectHandle}/>
                                                        </div>
                                                        {errors.mainCategory && <p className="text-red">{errors.mainCategory}</p>}
                                                    </div>

                                                    <div className="col-span-full">
                                                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Sub Category Title
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            autoComplete="given-name"
                                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                                                            value={formdata.title}
                                                            onChange={handleChange}
                                                            />
                                                        </div>
                                                        {errors.title && <p className="text-red">{errors.title}</p>}
                                                    </div>

                                                    <div className="col-span-full">
                                                        <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Description
                                                        </label>
                                                        <div className="mt-2">
                                                            <textarea
                                                            id="description"
                                                            name="description"
                                                            rows={3}
                                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                                                        
                                                            value={formdata.description}
                                                            onChange={handleChange}
                                                            />
                                                        </div>
                                                        {errors.description && <p className="text-red">{errors.description}</p>}
                                                    </div>                                               

                                                    <ProductImageUpload
                                                        imageSrc={formdata.imageSrc}
                                                        imageName={formdata.imageName}
                                                        onFileChange={fileHandle}
                                                        onRemove={() => setFormData(prevData => ({ ...prevData, imageSrc: null }))}
                                                        error={errors.image}
                                                    />  

                                                    <div className="col-span-full">
                                                        <button
                                                            type="submit"
                                                            className="w-full justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm "
                                                        >
                                                            Submit
                                                        </button>    
                                                    </div> 
                                                        
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </DialogPanel>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <Suspense
                    fallback={<div>Loading please wait...</div>}
                >
                    <SubCategories refresh={data}/>
                </Suspense>
            </div>
        </>
    )
}

export default AddSubCategory;