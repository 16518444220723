import React, { useState,useEffect }  from "react";
import axios from 'axios';
import config from "../../../config";
import leftArrow from '../../../assets/left-arrow.svg'
import trashIcon from '../../../assets/trash.svg';
import close from '../../../assets/cancel.svg';
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductImageUpload from "../components/ProductImageUpload";
import ProductPDFUpload from '../components/ProductPDFUpload';
import CKEditorWrapper from "../components/CKEditorWrapper";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

function EditProduct(){
    const { id }=useParams();
    const [status,setStatus]=useState(false);
    const [open, setOpen] = useState(false);
    const navigate=useNavigate();
    const [formdata,setFormData]=useState({
        parentCategory:null,
        subCategory:null,
        title:'',
        description:'',
        specifications:'',
        advantages:'',
        applications:'',
        image:null,
        pdf:null,
        imageSrc:null,
        pdfSrc:null,
        imageName:null,
        id:null,
        thumbnail:null,
        thumbnailSrc:null,
        thumbnailName:null
    });

    useEffect(()=>{
        axios.put(`${config.baseURL}/get-product`,{id:id}, { 
            withCredentials: true 
          })
        .then((res)=>{
            if(res.data.status==='success'){
                const data=res.data.data[0];
                // setTimeout(() => {
                    dataHandler(data);
                // }, 1000);
            }else{
                navigate('/product/products');
            }
        })
        .catch((err)=>console.log(err))

        axios.get(`${config.baseURL}/get-sub-categories`, { 
            withCredentials: true 
          })
        .then((res)=>{
            if(res.data.status==='success'){
                const options = res.data.data.map(item => ({
                    value: item.slug, 
                    label: item.name
                }));
                setOptions({
                    ...options,
                    categoryOptions:options,
                });
            }
        })
        .catch((err)=>console.log(err));
    },[id,status])

    const dataHandler=(data)=>{
        const media=JSON.parse(data.media);
        const other_data=JSON.parse(data.other_data);
        const imageSrc=`${config.baseURL}/media/${data.parent_category}/images/${media.image}`;
        const pdfSrc=`${config.baseURL}/media/${data.parent_category}/documents/${media.pdf}`;
        const thumbnailSrc=`${config.baseURL}/media/${data.parent_category}/thumbnails/${media.thumbnail}`;
        setFormData((prevData)=>({
            ...prevData,
            parentCategory:data.parent_category,
            subCategory:data.sub_category,
            title: data.name,
            description: data.description,
            specifications: other_data.specifications,
            advantages: other_data.advantages,
            applications: other_data.applications,
            imageSrc: imageSrc,
            pdfSrc: pdfSrc,
            imageName: media.image,
            id: data.id,
            thumbnailSrc:thumbnailSrc,
            thumbnailName:media.thumbnail
        }));
    }

    const notify = () => toast.success("Product updated successfully!", {
        position: "top-right"
    });

    const [options,setOptions]=useState({
        categoryOptions:{},
        subCategoryOptions:{}
    })
   
    const [errors, setErrors] = useState({}); 

    const validate = () => {
        let errors = {};

        if (!formdata.parentCategory) {
            errors.parentCategory = 'Category is required';
        }

        if (!formdata.subCategory) {
            errors.subCategory = 'Sub Category is required';
        }

        if (!formdata.title || formdata.title.trim()==="") {
          errors.title = 'Title is required';
        }

        if (!formdata.description || formdata.description.trim()==="") {
          errors.description = 'Description is required';
        }

        if (!formdata.specifications || formdata.specifications.trim()==="") {
            errors.specifications = 'Specifications is required';
        }

        if (!formdata.advantages || formdata.advantages.trim()==="") {
            errors.advantages = 'Advantages is required';
        }

        if (!formdata.applications || formdata.applications.trim()==="") {
            errors.applications = 'Applications is required';
        }

        if(formdata.imageSrc===null){
            if (!formdata.image) {
                errors.image = 'Image file is required';
            } else if (!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(formdata.image.type)) {
                errors.image = 'Only PNG and JPEG, JPG, WEBP images are allowed';
            }
        }
        if(formdata.pdfSrc===null){
            if (!formdata.pdf) {
                errors.pdf = 'PDF file is required';
            } else if (formdata.pdf.type !== 'application/pdf') {
                errors.pdf = 'Only PDF files are allowed';
            }
        }
    
        return errors;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formdata, 
          [name]: value 
        });
    };

    const fileHandle=(e)=>{
        const { name } = e.target;
        const file = e.target.files[0];

        if(name==='image'){
            const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
            if (validTypes.includes(file.type)) {
                setFormData((prevData) => ({
                    ...prevData, 
                    [name]: file 
                }));
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData((prevData) => ({
                        ...prevData,
                        imageSrc: reader.result, 
                        imageName:file.name
                    }));
                };
                reader.readAsDataURL(file); 
            }else{
                e.target.value = ''; 
            }
        }else if(name==='thumbnail'){
            const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
            if (validTypes.includes(file.type)) {
                setFormData({
                    ...formdata, 
                    [name]: file 
                });
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData((prevData) => ({
                        ...prevData,
                        thumbnailSrc: reader.result, 
                        thumbnailName:file.name
                    }));
                };
                reader.readAsDataURL(file); 
            }else{
                e.target.value = ''; 
            }
        }else{
          
            if(file.type === 'application/pdf'){
                setFormData({
                    ...formdata, 
                    [name]: file 
                });
                const pdfUrl = URL.createObjectURL(file); 
                setFormData((prevData) => ({
                    ...prevData,
                    pdfSrc: pdfUrl, 
                }));
            }else{
                e.target.value = ''; 
            }
        }
    }

    const formHandler=(e)=>{
        e.preventDefault();
        // console.log('Form submitted', formdata);
        const validationErrors = validate(); 
        if (Object.keys(validationErrors).length === 0) {
          const formData = new FormData();
          formData.append('category',formdata.parentCategory);
          formData.append('subCategory', formdata.subCategory);
          formData.append('description', formdata.description);
          formData.append('title', formdata.title);
          formData.append('image', formdata.image);
          formData.append('thumbnail', formdata.thumbnail);
          formData.append('pdf',formdata.pdf );
          formData.append('specifications',formdata.specifications );
          formData.append('advantages',formdata.advantages );
          formData.append('applications',formdata.applications);
          formData.append('id',formdata.id);

          let fileData='null';
          if(formdata.image != null){
            fileData='exists';
          }

          if(formdata.thumbnail != null){
            fileData='exists';
          }

          if(formdata.pdf != null){
            fileData='exists';
          }
          formData.append('fileData', fileData);

          axios.put(`${config.baseURL}/update-product`,formData, { 
            withCredentials: true 
          })
          .then((res)=>{
            if(res.data.status==="success"){
                setStatus(!status);
                notify(); 
            }
          })
          .catch((err)=>console.log(err))

        } else {
          setErrors(validationErrors);
        }
    }

    const [selectedValue,setSelectedValue]=useState({
        parentCategory:null,
        subCategory:null
    });

    const deleteHandler=()=>{
        axios.put(`${config.baseURL}/delete-product`,{id:id}, { 
            withCredentials: true 
          })
        .then((res)=>{
            // console.log(res);
            if(res.data.status==="success"){
                navigate('/product/products');
            }
        })
        .catch((err)=>console.log(err))
    }

    const categoryHandle = (selectedOption) => {
        if(selectedOption != null){
            axios.put(`${config.baseURL}/get-parent-sub-categories`,{data:selectedOption}, { 
                withCredentials: true 
              })
            .then((res)=>{
                if(res.data.status==='success'){
                    const getoptions = res.data.data.map(item => ({
                        value: item.slug, 
                        label: item.name
                    }));
                    // console.log(options);
                    setOptions((prevData)=>({
                        ...prevData,
                        subCategoryOptions:getoptions
                    }));
                }
            })
            .catch((err)=>console.log(err))
        }

        setFormData((prevData)=>({
            ...prevData,
            parentCategory:selectedOption.value
        }))

        // console.log(selectedOption);
        // console.log(selectedOption.value);

        setSelectedValue((prevData)=>({
            ...prevData,
            parentCategory:selectedOption
        }));
    };

    useEffect(()=>{
        if (options.categoryOptions.length > 0) {
            const parent_category = options.categoryOptions.find(option => option.value === formdata.parentCategory) || null;
            setSelectedValue((prevData)=>({
                ...prevData,
                parentCategory:parent_category
            }));
            categoryHandle(parent_category);
        }
    },[options.categoryOptions]);

    useEffect(()=>{
        if (options.subCategoryOptions && options.subCategoryOptions.length > 0) {
            const sub_category = options.subCategoryOptions.find(option => option.value === formdata.subCategory) || null;
            setSelectedValue((prevData)=>({
                ...prevData,
                subCategory:sub_category
            }));
        }
    },[options.subCategoryOptions]);

    return(
        <>
            <div className="w-full lg:w-6/12 font-noto">
                <ToastContainer />
                <div className="pb-12">
                    <div className="my-3"><Link to="/product/products"><img src={leftArrow} alt="Left-Icon" className="cursor-pointer" title="Back"/></Link></div>
                    <div className="flex  items-center justify-between my-5">
                        <h2 className="ms-3 text-lg font-semibold text-gray-900">Edit Product</h2>
                        <div><img src={trashIcon} alt="trash-icon" className="cursor-pointer" title="Delete" onClick={()=>setOpen(true)}/></div>
                    </div>
                    <form onSubmit={formHandler} className="ms-3 mt-3 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">
                        <div className="col-span-full">
                            <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
                                Product Category
                            </label>
                            <div className="mt-2">
                                <Select
                                    options={options.categoryOptions}
                                    value={selectedValue.parentCategory} 
                                    onChange={categoryHandle}
                                    id='category'
                                />
                            </div>
                            {errors.parentCategory && <p className="text-red">{errors.parentCategory}</p>}
                        </div>   
                        <div className="col-span-full">
                            <label htmlFor="subCategory" className="block text-sm font-medium leading-6 text-gray-900">
                                Sub Category
                            </label>
                            <div className="mt-2">
                                <Select
                                    options={options.subCategoryOptions}
                                    value={selectedValue.subCategory} 
                                    id='subCategory'
                                    onChange={(selectedOption)=>{
                                        setFormData((prevData)=>({...prevData,subCategory:selectedOption.value}))
                                        setSelectedValue((prevData)=>({...prevData,subCategory:selectedOption}))
                                    }}
                                />
                            </div>
                            {errors.subCategory && <p className="text-red">{errors.subCategory}</p>}
                        </div>                              
                        <div className="col-span-full">
                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                Product Title
                            </label>
                            <div className="mt-2">
                                <input
                                    id="title"
                                    name="title"
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-insetsm:text-sm sm:leading-6 focus:ring-red focus:outline-none"
                                    value={formdata.title && formdata.title}
                                    onChange={handleChange}
                                    
                                />
                            </div>
                            {errors.title && <p className="text-red">{errors.title}</p>}
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                Description
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    rows={5}
                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                                    value={formdata.description && formdata.description}
                                    onChange={handleChange}
                                />
                            </div>
                            {errors.description && <p className="text-red">{errors.description}</p>}
                        </div>

                        <CKEditorWrapper 
                            id="specifications"
                            label="Specifications"
                            data={formdata.specifications}
                            onChange={(data) => setFormData((prevData)=>({...prevData,specifications:data}))}
                            error={errors.specifications}
                        />
                        <CKEditorWrapper
                          id="advantages"
                          label="Advantages"
                          data={formdata.advantages}
                          onChange={(data) => setFormData((prevData)=>({ ...prevData,advantages:data}))}
                          error={errors.advantages}
                        />

                        <CKEditorWrapper
                            id="applications"
                            label="Applications"
                            data={formdata.applications}
                            onChange={(data) =>  setFormData((prevData)=>({ ...prevData, applications:data}))}
                            error={errors.applications}
                        />

                        <ProductImageUpload
                            label="Product Thumbnail"
                            name="thumbnail"
                            imageSrc={formdata.thumbnailSrc}
                            imageName={formdata.thumbnailName}
                            onFileChange={fileHandle}
                            onRemove={() => setFormData((prevData)=>({ ...prevData, thumbnailSrc: null,  thumbnail: null}))}
                            error={errors.thumbnail}
                        />  

                        <ProductImageUpload
                          imageSrc={formdata.imageSrc}
                          imageName={formdata.imageName}
                          onFileChange={fileHandle}
                          onRemove={() => setFormData((prevData)=>({ ...prevData, imageSrc: null }))}
                          error={errors.image}
                        />  

                        <ProductPDFUpload 
                            pdfSrc={formdata.pdfSrc}
                            onFileChange={fileHandle}
                            onRemove={() => setFormData((prevData)=>({ ...prevData, pdfSrc: null }))}
                            error={errors.pdf}
                        />

                        <div className="mt-6 flex items-center justify-end gap-x-6 col-span-full">
                            <Link to="/product/products" className="text-sm  leading-6 text-gray-900">Cancel</Link>
                            <button type="submit" className="rounded-md bg-red px-3 py-2 text-sm  text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>   
                        </div>     
                    </form>
                </div>
                <Dialog open={open} onClose={setOpen} className="relative z-10">  
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="flex align-middle justify-between px-4 py-4 border-b-2">
                                <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                    Confirm Delete
                                </DialogTitle>
                                <div className="flex align-middle justify-end ">
                                    <img className="hover:cursor-pointer" onClick={() => setOpen(false)} src={close} alt="close-img"/>
                                </div>
                            </div>
                            
                            <div className="bg-white px-4 pb-4 pt-0 sm:pb-4">
                                <div className="sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <div className="mt-2">
                                            <p className="text-center font-noto text-base my-5">You are about to delete this Product. </p>
                                             <p className="text-center font-noto text-base my-5">Do you want to proceed?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button"   className='inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto bg-red' onClick={deleteHandler} >Delete</button>
                                <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={() => setOpen(false)} >Cancel</button>
                            </div>
                        </DialogPanel>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default EditProduct