import React, { useRef, useState, Suspense, lazy } from 'react'
import uploadSvg from '../../assets/upload.svg';
import config from '../../config';
import axios from 'axios';
const Gallery = lazy(() => import('./Gallery'));

const AddGallery = () => {
    const [refresh, setRefresh]=useState(false);
    const inputFile=useRef(null);
    const fileHandle=()=>{
        inputFile.current.click();
    }

    const uploadHandler=(e)=>{
        const file = e.target.files[0];
        const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
        if (validTypes.includes(file.type)) {
            const formData=new FormData();
            formData.append('image', file);
            axios.post(`${config.baseURL}/add-image`, formData, {
                withCredentials:true
            }) 
            .then((res)=>{
                // console.log(res);
                if(res.data.status==='success'){
                    setRefresh(!refresh);
                    e.target.value = ''; 
                }
            })
            .catch((err)=>{
                console.log(err);
            })
        }else{
            e.target.value = ''; 
        }
    }
  return (
    <div className='my-10'>
        <div>
            <div className='text-center'>
                <input type="file" className='hidden' ref={inputFile} onChange={uploadHandler}/>
                <button className='bg-red text-white px-10 py-3 rounded-full flex items-center justify-center gap-3 mx-auto' onClick={fileHandle}><img src={uploadSvg} alt='upload'/><span>Upload File</span></button>
            </div>
        </div>
        <Suspense
            fallback={<div>Loading please wait...</div>}
        >
            <Gallery refresh={refresh} />
        </Suspense>
    </div>
  )
}

export default AddGallery