import React, { useState, useEffect, Suspense, lazy }  from "react";
import axios from 'axios';
import config from "../../../config";
import leftArrow from '../../../assets/left-arrow.svg';
import trashIcon from '../../../assets/trash.svg';
import close from '../../../assets/cancel.svg';
import { Link, useNavigate , useParams  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
const CKEditorWrapper = lazy(() => import("../components/CKEditorWrapper"));
const ProductImageUpload = lazy(() => import("../components/ProductImageUpload"));
const ProductPDFUpload = lazy(() => import("../components/ProductPDFUpload"));

function EditCategory(){
    const { id }=useParams();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [status,setStatus]=useState(false);
    const notify = () => toast.success("Category updated successfully!", {
        position: "top-right"
    });

    const [formdata,setFormData]=useState({
        title:'',
        description:'',
        features:'',
        image:null,
        pdf:null,
        id:null,
        imageSrc:null,
        pdfSrc:null,
        imageName:null,
        numOfSubCategories:null
    });

    useEffect(()=>{
      axios.put(`${config.baseURL}/get-category`,{id:id},{ 
        withCredentials: true 
      })
      .then((res)=>{
        if(res.data.status==='success'){
            const data=res.data.data[0];
           
            const checkdata=res.data.checkData.subCategory;
            const media = JSON.parse(data.media); 
            const imageSrc=`${config.baseURL}/media/${data.slug}/images/${media.image}`;
            const pdfSrc=`${config.baseURL}/media/${data.slug}/documents/${media.pdf}`;
            setFormData({
                title:data.name,
                description:data.description,
                features:data.features,
                image:null,
                pdf:null,
                id:data.id,
                imageSrc: imageSrc,
                pdfSrc:pdfSrc,
                imageName: media.image,
                numOfSubCategories:checkdata
           });
        }else{
            navigate('/product/categories')
        }
      })
      .catch((error)=>{
        console.error(error);
      })
    },[id,status,navigate])
   
    const [errors, setErrors] = useState({}); 

    const validate = () => {
        let errors = {};
        // if (!formdata.title || formdata.title.trim()==="") {
        //   errors.title = 'Title is required';
        // }

        if (!formdata.description || formdata.description.trim()==="") {
          errors.description = 'Description is required';
        }

        if (!formdata.features || formdata.features.trim()==="") {
            errors.features = 'Features is required';
        }
  
        if(formdata.imageSrc===null){
            if (!formdata.image) {
                errors.image = 'Image file is required';
            } else if (!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(formdata.image.type)) {
                errors.image = 'Only PNG and JPEG, JPG, WEBP images are allowed';
            }
        }
        
        if(formdata.pdfSrc===null){
            if (!formdata.pdf) {
                errors.pdf = 'PDF file is required';
            } else if (formdata.pdf.type !== 'application/pdf') {
                errors.pdf = 'Only PDF files are allowed';
            }
        }
    
        return errors;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formdata, 
          [name]: value 
        });
    };

    const fileHandle=(e)=>{
        const { name } = e.target;
        const file = e.target.files[0];

        if(name==='image'){
            const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
            if (validTypes.includes(file.type)) {
                setFormData((prevData) => ({
                    ...prevData, 
                    [name]: file 
                }));
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData((prevData) => ({
                        ...prevData,
                        imageSrc: reader.result, 
                        imageName:file.name
                    }));
                };
                reader.readAsDataURL(file); 
            }else{
                e.target.value = ''; 
            }
        }else{
          
            if(file.type === 'application/pdf'){
                setFormData({
                    ...formdata, 
                    [name]: file 
                });
                const pdfUrl = URL.createObjectURL(file); 
                setFormData((prevData) => ({
                    ...prevData,
                    pdfSrc: pdfUrl, 
                }));
            }else{
                e.target.value = ''; 
            }
        }
    }

    const formHandler=(e)=>{
        e.preventDefault();
        const validationErrors = validate(); 
        if (Object.keys(validationErrors).length === 0) {
          //   console.log('Form submitted', formdata);
          const formData = new FormData();
          formData.append('id', formdata.id);
          formData.append('description', formdata.description);
          formData.append('features', formdata.features);
          formData.append('image', formdata.image);
          formData.append('pdf',formdata.pdf );
          let fileData='null';
          if(formdata.image != null){
            fileData='exists';
          }

          if(formdata.pdf != null){
            fileData='exists';
          }
          formData.append('fileData', fileData);

          axios.put(`${config.baseURL}/update-category`,formData,{ 
            withCredentials: true 
          })
          .then((res)=>{
     
            if(res.data.status==="success"){
                setStatus(!status);
                notify();
            }

          })
          .catch((err)=>console.log(err))

        } else {
          setErrors(validationErrors);
        }
    }

    const deleteHandler=()=>{
        axios.put(`${config.baseURL}/delete-category`,{id:id},{ 
            withCredentials: true 
          })
        .then((res)=>{
            // console.log(res);
            if(res.data.status==="success"){
                navigate('/product/categories')
            }
        })
        .catch((err)=>console.log(err))
    }

    return(
        <div className="w-6/12">
             <ToastContainer />
            <div className="pb-12">
                <div className="my-3"><Link to="/product/categories"><img src={leftArrow} alt="Left-Icon" className="cursor-pointer" title="Back"/></Link></div>
                <div className="flex  items-center justify-between my-5">
                    <h2 className="ms-3 text-lg font-semibold text-gray-900">Edit Category</h2>
                    <div><img src={trashIcon} alt="trash-icon" className="cursor-pointer" title="Delete" onClick={()=>setOpen(true)}/></div>
                </div>
                <form onSubmit={formHandler} className="ms-3 mt-3 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">                               
                    <div className="col-span-full">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Category Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="title"
                                name="title"
                                type="text"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-insetsm:text-sm sm:leading-6  cursor-not-allowed"
                                value={formdata.title}
                                onChange={handleChange}
                                readOnly
                            />
                        </div>
                        {errors.title && <p className="text-red">{errors.title}</p>}
                    </div>
                    <div className="col-span-full">
                        <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                            Description
                        </label>
                        <div className="mt-2">
                            <textarea
                                id="description"
                                name="description"
                                rows={5}
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                            
                                value={formdata.description}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.description && <p className="text-red">{errors.description}</p>}
                    </div>

                    <Suspense fallback={<div>Loading Editor...</div>}>
                        <CKEditorWrapper 
                            id="features"
                            label="Features"
                            data={formdata.features}
                            onChange={(data) => setFormData((prevData)=>({...prevData,features:data}))}
                            error={errors.features}
                        />
                    </Suspense>
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        <ProductImageUpload
                            imageSrc={formdata.imageSrc}
                            imageName={formdata.imageName}
                            onFileChange={fileHandle}
                            onRemove={() => setFormData({ ...formdata, imageSrc: null })}
                            error={errors.image}
                        />  
                    </Suspense>
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        <ProductPDFUpload 
                            pdfSrc={formdata.pdfSrc}
                            onFileChange={fileHandle}
                            onRemove={() => setFormData({ ...formdata, pdfSrc: null })}
                            error={errors.pdf}
                        />
                    </Suspense>

                    <div className="mt-6 flex items-center justify-end gap-x-6 col-span-full">
                        <Link to="/product/categories" className="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                        <button type="submit" className="rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>   
                    </div>     
                </form>
            </div>

            <Dialog open={open} onClose={setOpen} className="relative z-10">  
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="flex align-middle justify-between px-4 py-4 border-b-2">
                            <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                Confirm Delete
                            </DialogTitle>
                            <div className="flex align-middle justify-end ">
                                <img className="hover:cursor-pointer" onClick={() => setOpen(false)} src={close} alt="close-img"/>
                            </div>
                        </div>
                        
                        <div className="bg-white px-4 pb-4 pt-0 sm:pb-4">
                            <div className="sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <div className="mt-2">
                                        <p className="text-center font-noto text-base my-5">You are about to delete this Category. {formdata.numOfSubCategories !== 0 ? 'Everything under this sub categories will be deleted first.' : ''}</p>
                                        {formdata.numOfSubCategories !== 0 ? null : <p className="text-center font-noto text-base my-5">Do you want to proceed?</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button type="button"   className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto 
                                ${formdata.numOfSubCategories !== 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-red hover:bg-red-500'}`} onClick={deleteHandler} disabled={formdata.numOfSubCategories !== 0}>Delete</button>
                            <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={() => setOpen(false)} >Cancel</button>
                        </div>
                    </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditCategory