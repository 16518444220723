import React from 'react';
import close from '../../assets/cancel.svg';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import ProductImageUpload from "../products/components/ProductImageUpload";

const EditCertificate = ({
  open,
  onClose,
  onSubmit,
  title = "Edit Certificate",
  formData,
  handleChange,
  errors = {},
  submitButtonLabel = "Submit",
  onFileChange,
  onFileRemove
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">  
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                    <div className="flex align-middle justify-between px-4 py-4">
                        <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                            {title}
                        </DialogTitle>
                        <div className="flex align-middle justify-end">
                            <img className="hover:cursor-pointer" onClick={onClose} src={close} alt="close-img" />
                        </div>
                    </div>
                    
                    <div className="bg-white px-4 pb-4 pt-0 sm:pb-4">
                        <div className="sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <div className="mt-2">
                                    <form onSubmit={onSubmit} className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">
                                        {/* Dynamic Form Fields */}
                                        <div className="col-span-full">
                                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                                Certificate Regd
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="title"
                                                    name="regd"
                                                    type="text"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                                                    value={formData.regd || ""}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {errors.regd && <p className="text-red-500">{errors.regd}</p>}
                                        </div>
                                        
                                        {/* Dynamic File Upload */}
                                        <div className="col-span-full">
                                            <ProductImageUpload
                                                imageSrc={formData.imageSrc}
                                                imageName={formData.imageName}
                                                onFileChange={onFileChange}
                                                onRemove={onFileRemove}
                                                error={errors.image}
                                            />
                                        </div>

                                        {/* Submit Button */}
                                        <div className="col-span-full">
                                            <button
                                                type="submit"
                                                className="w-full justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm"
                                            >
                                                {submitButtonLabel}
                                            </button>    
                                        </div>     
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </div>
    </Dialog>
  )
}

export default EditCertificate;
