import React, { useEffect, useState, useRef } from 'react';
import '../../dataTables.css'
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import config from "../../config";
import trashSvg from '../../assets/trash2.svg';
import editSvg from '../../assets/pen.svg'
import EditCertificate from './EditCertificate';
import { ToastContainer, toast } from 'react-toastify';
import DeleteCertificate from './DeleteCertificate';

const Certificates = (props) => {
  const [refresh, setRefresh]=useState(false);
  const [open, setOpen] = useState({
    editModal:false,
    deleteModal:false
 });
  const [data, setData] = useState([]);
  const tableRef = useRef(null);
  const [formdata,setFormData]=useState({
      regd:'',
      image:null,
      imageSrc:null,
      imageName:null,
      id:null
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/get-documents`,{ 
        withCredentials: true 
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const editHandle = (data) => {
    const path=config.baseURL+'/media/documents/'+data.id+'/'+data.attachment;
    setFormData((prevData)=>({
        ...prevData,
        regd:data.regd_no,
        imageSrc:path,
        imageName:data.attachment,
        id:data.id,
    }));
    setOpen((prevData)=>({...prevData, editModal:true}));
  }

  const deleteHandle = (data) => {
      setFormData((prevData)=>({
        ...prevData,
        regd:data.regd_no,
        imageSrc:data.attachment,
        imageName:data.attachment,
        id:data.id,
      }));
     setOpen((prevData)=>({...prevData, deleteModal:true}));
  }

  useEffect(() => {
    fetchData();
  }, [props.refresh, refresh]);

  useEffect(() => {
    if (tableRef.current) {
      const table = $(tableRef.current).DataTable({
        data: data,
        columns: [
          { title: "S No", data: null, render: (data, type, row, meta) => meta.row + 1 },
          { title: "Regd", data: "regd_no" ,
            render: function(data){
              return `<span>`+data + `</span>`
            }
          },
          { 
            title: "Image", 
            data: null,
            render: function(data, type, row) {
              return `<img src="${config.baseURL}/media/documents/${row.id}/${row.attachment}" alt="${row.attachment}" class="max-h-24 max-w-24 object-contain" />`; 
            }
          },
          { 
            title: "Action", 
            data: null,
            render: function(data, type, row) {
              return `<button class="btn edit-btn bg-red me-4 p-2 rounded-md" ><img src='${editSvg}' alt='edit'/></button> <button class="btn bg-red delete-btn p-2 rounded-md" ><img src='${trashSvg}' alt='edit'/></button>`;
            }
          }
        
        ],

        responsive:true,
      
        rowCallback: (row, data) => {
          $('#projectsTable').off('click', '.edit-btn').on('click', '.edit-btn', function() {
            const rowData = $('#projectsTable').DataTable().row($(this).closest('tr')).data();
            editHandle(rowData);
          });
          $('#projectsTable').off('click', '.delete-btn').on('click', '.delete-btn', function() {
            const rowData = $('#projectsTable').DataTable().row($(this).closest('tr')).data();
            deleteHandle(rowData);
          });
        },
     
      });

      return () => {
        if (table) {
          table.destroy();
        }
      };
    }

    
  }, [data]);

  const notify = (msg) => toast.success(msg, {
      position: "top-right"
  });

  const [errors, setErrors] = useState({}); 

  const validate = () => {
      let errors = {};
      
      if (formdata.regd.trim()==="") {
          errors.regd = 'Regd is required';
      }
    
      if (!formdata.image) {
        errors.image = 'Image file is required';
      } else if (!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(formdata.image.type)) {
        errors.image = 'Only PNG and JPEG, JPG, WEBP images are allowed';
      }
  
      return errors;
  };
  
  const onFileRemove=()=>{
    setFormData((prevData) => ({ ...prevData, imageSrc: null }));
  }
  
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData)=>({
        ...prevData, 
        [name]: value 
      }));
  };

  const onClose=()=>{
    setOpen((prevData)=>({...prevData, editModal:false}));
  };

  const deleteClose=()=>{
    setOpen((prevData)=>({...prevData, deleteModal:false}));
  };


  const fileHandle=(e)=>{
      const { name } = e.target;
      const file = e.target.files[0];
      const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
      if (validTypes.includes(file.type)) {
          setFormData((prevData) => ({
              ...prevData, 
              [name]: file 
          }));
          const reader = new FileReader();
          reader.onloadend = () => {
              setFormData((prevData) => ({
                  ...prevData,
                  imageSrc: reader.result, 
                  imageName:file.name
              }));
          };
          reader.readAsDataURL(file); 
      }else{
          e.target.value = ''; 
      }   
  }

  const formHandler=(e)=>{
      e.preventDefault();
      const validationErrors = validate(); 
      if (Object.keys(validationErrors).length === 0) {
        const formData = new FormData();
        formData.append('regd', formdata.regd);
        formData.append('image', formdata.image);
        formData.append('id', formdata.id);
        let fileData='null';
        if(formdata.image != null){
          fileData='exists';
        }
        formData.append('fileData', fileData);
        axios.put(`${config.baseURL}/update-document`,formData,{ 
          withCredentials: true 
        })
        .then((res)=>{
          // console.log(res.data.data)
          if(res.data.status==="success"){
              setRefresh(!refresh);
              setOpen((prevData)=>({...prevData, editModal:false}));
              setFormData({
                  regd:'',
                  image:null,
                  imageSrc:null,
                  imageName:null
              });
              notify("Certificate added successfully!");
          }

        })
        .catch((err)=>console.log(err))

      } else {
          setErrors((prevErrors) => ({
              ...prevErrors,    
              ...validationErrors,  
          }));
      }
  }

  const deleteData =()=>{
    //alert('hi');
    axios.delete(`${config.baseURL}/delete-document/${formdata.id}`,{
      withCredentials:true
    })
    .then((res)=>{
      if(res.data.status==="success"){
          setRefresh(!refresh);
          setOpen((prevData)=>({...prevData, deleteModal:false}));
          setFormData({
              regd:'',
              image:null,
              imageSrc:null,
              imageName:null
          });
          notify("Certificate deleted successfully!");
      }
    })
    .catch((err)=>{
      console.log(err);
    });


  }

  return (
    <>
      <ToastContainer/>
      <div className="overflow-x-auto table-responsive ">
          <table id="projectsTable" ref={tableRef} className="display cell-border compact hover order-column row-border stripe w-full text-left table table-striped">
              <thead>
                  <tr>
                    <th datapriority="1">S.No</th>
                    <th datapriority="2">Image</th>
                    <th>Regd No.</th>
                    <th>Action</th>
                  </tr>
              </thead>
              <tbody>
              </tbody>
          </table>
      </div>
      <EditCertificate  open={open.editModal} onClose={onClose} onSubmit={formHandler} formData={formdata} handleChange={handleChange} errors = {errors} onFileChange={fileHandle} onFileRemove={onFileRemove} />
      <DeleteCertificate  open={open.deleteModal} onClose={deleteClose} onSubmit={deleteData}  />
    </>
  );
};

export default Certificates;
