import React from 'react';
import pdfIcon from '../../../assets/PDF_icon.svg.png';
import cancelIcon from '../../../assets/cancel.svg';
import pdf from '../../../assets/pdf.svg';

const ProductPDFUpload = ({ pdfSrc, onFileChange, onRemove, error }) => {
  return (
    <div className="col-span-full">
      <label htmlFor="pdf-upload" className="block text-sm font-medium leading-6 text-gray-900">
        Product PDF
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4 relative">
        {pdfSrc === null ? (
          <div className="text-center">
            <img src={pdf} alt="pdf" aria-hidden="true" className="mx-auto h-10 w-10" />
            <div className="mt-2 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="pdf-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600"
              >
                <span>Upload a PDF</span>
                <input id="pdf-upload" name="pdf" type="file" className="sr-only" onChange={onFileChange} />
              </label>
            </div>
            <p className="text-xs leading-5 text-gray-600">PDF up to 10MB</p>
          </div>
        ) : (
          <div>
            <div className="px-3 right-0 absolute">
              <img
                src={cancelIcon}
                alt="cancel-icon"
                className="w-5 cursor-pointer"
                title="Remove"
                onClick={onRemove}
              />
            </div>
            <div className="text-center">
              <img src={pdfIcon} alt="pdf" aria-hidden="true" className="mx-auto w-8" />
              <div className="mt-2 flex text-sm leading-6 text-gray-600">
                <a href={pdfSrc} target="_blank" rel="noopener noreferrer">
                  View PDF
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {error && <p className="text-red">{error}</p>}
    </div>
  );
};

export default ProductPDFUpload;
