import React, { useState, useEffect, useRef, Suspense, lazy }  from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import axios from 'axios';
import config from "../../../config";
import close from '../../../assets/cancel.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CKEditorWrapper = lazy(() => import("../components/CKEditorWrapper"));
const ProductImageUpload = lazy(() => import("../components/ProductImageUpload"));
const ProductPDFUpload = lazy(() => import("../components/ProductPDFUpload"));
const Categories = lazy(() => import("./Categories"));

const  AddCategory = React.memo(() =>{
    const notify = () => toast.success("Category added successfully!", {
        position: "top-right"
    });

    const [data,setData]=useState(false);
    const [open, setOpen] = useState(false);
    const debounceTimeout = useRef(null); 
    const [formdata,setFormData]=useState({
        title:'',
        description:'',
        features:'',
        image:null,
        pdf:null,
        imageSrc:null,
        pdfSrc:null,
        imageName:null
    });
    const [errors, setErrors] = useState({}); 

    const validate = () => {
        let errors = {};
        
        if (formdata.title.trim()==="") {
            errors.title = 'Title is required';
        }

        if (!formdata.description || formdata.description.trim()==="") {
          errors.description = 'Description is required';
        }

        if (!formdata.features || formdata.features.trim()==="") {
            errors.features = 'Features is required';
          }

        if (!formdata.image) {
          errors.image = 'Image file is required';
        } else if (!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(formdata.image.type)) {
          errors.image = 'Only PNG and JPEG, JPG, WEBP images are allowed';
        }

        if (!formdata.pdf) {
          errors.pdf = 'PDF file is required';
        } else if (formdata.pdf.type !== 'application/pdf') {
          errors.pdf = 'Only PDF files are allowed';
        }
    
        return errors;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData)=>({
          ...prevData, 
          [name]: value 
        }));
    };

    const fileHandle=(e)=>{
        // console.log(e.target.files[0]);
        const { name } = e.target;
        const file = e.target.files[0];
        if(name==='image'){
            const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
            if (validTypes.includes(file.type)) {
                setFormData((prevData) => ({
                    ...prevData, 
                    [name]: file 
                }));
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData((prevData) => ({
                        ...prevData,
                        imageSrc: reader.result, 
                        imageName:file.name
                    }));
                };
                reader.readAsDataURL(file); 
            }else{
                e.target.value = ''; 
            }
        }else{
            if(file.type === 'application/pdf'){
                setFormData({
                    ...formdata, 
                    [name]: file 
                });
                const pdfUrl = URL.createObjectURL(file); 
                setFormData((prevData) => ({
                    ...prevData,
                    pdfSrc: pdfUrl, 
                }));
            }else{
                e.target.value = ''; 
            }
        }
    }

    const formHandler=(e)=>{
        e.preventDefault();
        const validationErrors = validate(); 
        if (Object.keys(validationErrors).length === 0) {
          //   console.log('Form submitted', formdata);
          const formData = new FormData();
          formData.append('title', formdata.title);
          formData.append('description', formdata.description);
          formData.append('features', formdata.features);
          formData.append('image', formdata.image);
          formData.append('pdf',formdata.pdf );

          axios.put(`${config.baseURL}/add-category`,formData,{ 
            withCredentials: true 
          })
          .then((res)=>{
            // console.log(res.data.data)
            if(res.data.status==="success"){
                setOpen(false);
                setFormData({
                    title:'',
                    description:'',
                    features:'',
                    image:null,
                    pdf:null,
                    imageSrc:null,
                    pdfSrc:null,
                    imageName:null
                });
                setData(!data);
                notify();
            }

          })
          .catch((err)=>console.log(err))

        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,    
                ...validationErrors,  
            }));
        }
    }

    useEffect(() => {
      setErrors({});
    }, [open]);

    const titleHandler=(e)=>{
        const title=e.target.value;
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            axios.post(`${config.baseURL}/check-category`,{title:title},{ 
                withCredentials: true 
              })
            .then((res)=>{
                if(res.data.status==='success'){
                    if(res.data.message==='Found'){
                        setErrors((prevData)=>({
                            ...prevData,
                            title:`${res.data.data[0].name} already exists`
                        }))
                    }else{
                        setErrors({}); 
                    }
                }else{
                    setErrors({}); 
                }
            })
            .catch((err)=>console.log(err))
        }, 1000);
    }
    
    return(
        <>
            <div className="p-4 max-w-full mx-auto mt-12 font-noto">
                <ToastContainer />
                <div className="flex justify-between mb-4">
                    <p className='text-2xl font-bold text-black'>Categories</p>
                    <div>
                        <button className="bg-red text-white font-noto rounded-xl px-4 py-2" onClick={()=>setOpen(true)}>Add Category</button>
                    </div>
                    <Dialog open={open} onClose={setOpen} className="relative z-10">  
                        <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        />
                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="flex align-middle justify-between px-4 py-4">
                                    <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                        Add Category
                                    </DialogTitle>
                                    <div className="flex align-middle justify-end ">
                                        <img className="hover:cursor-pointer" onClick={() => setOpen(false)} src={close} alt="close-img"/>
                                    </div>
                                </div>
                                
                                <div className="bg-white px-4 pb-4 pt-0 sm:pb-4">
                                    <div className="sm:items-start">
                                        {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                                        </div> */}
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <div className="mt-2">
                                                <form onSubmit={formHandler} className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">
                                                    <div className="col-span-full">
                                                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Category Name
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="title"
                                                                name="title"
                                                                type="text"
                                                                autoComplete="given-name"
                                                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                                                                value={formdata.title}
                                                                onChange={handleChange}
                                                                onKeyUp={titleHandler}
                                                            />
                                                        </div>
                                                        {errors.title && <p className="text-red">{errors.title}</p>}
                                                    </div>
                                                    <div className="col-span-full">
                                                        <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Description
                                                        </label>
                                                        <div className="mt-2">
                                                            <textarea
                                                            id="description"
                                                            name="description"
                                                            rows={3}
                                                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                                                        
                                                            value={formdata.description}
                                                            onChange={handleChange}
                                                            />
                                                        </div>
                                                        {errors.description && <p className="text-red">{errors.description}</p>}
                                                    </div>

                                                    <Suspense fallback={<div>Loading Editor...</div>}>
                                                        <CKEditorWrapper 
                                                            id="features"
                                                            label="Features"
                                                            data={formdata.features}
                                                            onChange={(data) => setFormData((prevData)=>({...prevData,features:data}))}
                                                            error={errors.features}
                                                        />
                                                    </Suspense>

                                                    <Suspense fallback={<div>Loading Editor...</div>}>
                                                        <ProductImageUpload
                                                            imageSrc={formdata.imageSrc}
                                                            imageName={formdata.imageName}
                                                            onFileChange={fileHandle}
                                                            onRemove={() => setFormData(prevData => ({ ...prevData, imageSrc: null }))}
                                                            error={errors.image}
                                                        />  
                                                    </Suspense>

                                                    <Suspense fallback={<div>Loading Editor...</div>}>
                                                        <ProductPDFUpload 
                                                            pdfSrc={formdata.pdfSrc}
                                                            onFileChange={fileHandle}
                                                            onRemove={() => setFormData(prevData => ({ ...prevData, pdfSrc: null }))}
                                                            error={errors.pdf}
                                                        />
                                                    </Suspense>

                                                    <div className="col-span-full">
                                                        <button
                                                            type="submit"
                                                            className="w-full justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm "
                                                        >
                                                            Submit
                                                        </button>    
                                                    </div> 
                                                        
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </DialogPanel>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <Suspense
                    fallback={<div>Loading please wait...</div>}
                >
                    <Categories refresh={data}/>
                </Suspense>
            </div>
        </>
    )
});

export default AddCategory;