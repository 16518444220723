
import '../index.css'
import { Home, StickyNote, ShoppingBag, LogOut, Images, Contact, Users} from "lucide-react";
import { SidebarItem } from "./sidebar/SidebarItem";
import Sidebar from "./sidebar/Sidebar";

function Header(){
    return(
        <Sidebar>
            {/* <SidebarItem
                icon={<Home size={20} />}
                text="Dashboard"
                id="dashboard"
            /> */}
            <SidebarItem
                icon={<ShoppingBag size={20} />}
                text="Products"
                id="product"
                dropdownItems={[
                    { text: "Categories", path: "/product/categories" },
                    { text: "Sub Categories", path: "/product/sub-categories" },
                    { text: "Products", path: "/product/products" }
                ]}
            />
            {/* <SidebarItem
                icon={<LayoutDashboard size={20} />}
                text="Case Studies"
                id="case-studies"
            /> */}

            <SidebarItem
                icon={<Contact size={20} />}
                text="Inquires"
                id="inquires"
            />

            <SidebarItem
                icon={<Users size={20} />}
                text="Careers"
                id="careers"
            />

            <SidebarItem
                icon={<StickyNote size={20} />}
                text="Certificates"
                id="certificates"
            />

            <SidebarItem
                icon={<Images size={20} />}
                text="Gallery"
                id="gallery"
            />
            <hr className="my-3" />
           
            <SidebarItem
                icon={<LogOut size={20} />}
                text="Logout"
                id="logout"
            />
        </Sidebar>
    )
}

export default Header;